<template>
  <div class="mw780" :class="$style.wrap">
    <!-- <div :class="$style.course">
      <SvgoIconRedo :class="$style.icon" />
      <CommonExchangeCourse is-home></CommonExchangeCourse>
    </div> -->
    <client-only>
      <ExchangeOperatingMode
        :class="$style.mode"
        :is-weekend="isWeekend"
      ></ExchangeOperatingMode>
    </client-only>
    <div :class="$style.directions">
      <div :class="$style.direction">
        <h3 class="p">Отдаете</h3>
        <UiComboboxVComboBox
          :class="$style.combobox"
          :options="fromDirections"
          :current-name="fromDirection?.name"
          :current-image="fromDirection?.logo.simple"
          label-name="name"
          value-name="id"
          image-name="simple"
          filters-direction
          @change="onFromCurrencyChange"
        />
        <div :class="$style.limits">
          <span v-if="formInputsMeta?.from?.min"
            >Мин. сумма
            <span>
              {{
                roundNumber(
                  formInputsMeta?.from?.min,
                  formInputsMeta?.from?.round,
                )
              }}
            </span>
          </span>
          <span v-if="formInputsMeta?.from?.max"
            >Макс. сумма
            <span>
              {{
                roundNumber(
                  formInputsMeta?.from?.max,
                  formInputsMeta?.from?.round,
                )
              }}</span
            >
          </span>
        </div>
        <CommonExchangeNetwork
          :options="fromOptions"
          :class="$style.networks"
          @option-change="onFromCurrencyChange($event)"
        />
        <UiInputVInputThousands
          v-if="fromValue !== null"
          :value="fromValue"
          :decimal-count="formInputsMeta?.from?.round"
          @input="handleInputFrom"
          @change="handleChangeFrom"
        >
          <img
            :src="fromDirection?.logo.simple"
            :alt="fromDirection?.name"
            :class="$style.currency"
          />
        </UiInputVInputThousands>
        <p :class="$style.sales">Ваша скидка: {{ discount }}%</p>
        <NuxtLink to="/discounts">Как повысить?</NuxtLink>
      </div>
      <div :class="$style.direction">
        <h3 class="p">Получаете</h3>
        <UiComboboxVComboBox
          :class="[$style.combobox, $style.second]"
          :options="toDirections"
          :current-name="toDirection?.name"
          :current-image="toDirection?.logo.simple"
          label-name="name"
          value-name="id"
          image-name="simple"
          filters-direction
          @change="onToCurrencyChange"
        />
        <div :class="$style.row"></div>
        <CommonExchangeNetwork
          :options="toOptions"
          :class="$style.networks"
          @option-change="onToCurrencyChange($event)"
        />
        <UiInputVInputThousands
          v-if="toValue !== null"
          :value="toValue"
          :decimal-count="formInputsMeta?.to?.round"
          @input="handleInputTo"
          @change="handleChangeTo"
        >
          <img
            :src="toDirection?.logo.simple"
            :alt="toDirection?.name"
            :class="$style.currency"
          />
        </UiInputVInputThousands>
      </div>
    </div>
    <UiButtonVButton
      :class="$style.btn"
      :disabled="isButtonBlocked"
      @click="onChange"
    >
      Начать обмен
    </UiButtonVButton>
  </div>
</template>

<script setup lang="ts">
import { useCalculator } from "~/composables/useCalculator";
import NotificationModal from "~/components/layout/modals/NotificationModal.vue";
import ExchangeOperatingMode from "~/components/pages/home/exchange/ExchangeOperatingMode.vue";

defineProps({
  step: {
    type: Number,
    required: true,
  },
  isButtonBlocked: {
    type: Boolean,
    default: false,
  },
  isWeekend: {
    type: Boolean,
    required: true,
  },
});

const {
  init,
  setInitialCurrencies,
  fromDirections,
  toDirections,
  fromDirection,
  toDirection,
  onFromCurrencyChange,
  onToCurrencyChange,
  fromOptions,
  toOptions,
  fromValue,
  toValue,
  formInputsMeta,
  handleInputFrom,
  handleInputTo,
  handleChangeFrom,
  handleChangeTo,
  discount,
  formValues,
  notifications,
} = useCalculator();

const { $modal } = useNuxtApp();

function onChange() {
  if (isLoading.value) return;
  if (formValues) {
    formValues.value.from.card = "";
    formValues.value.to.card = "";
  }
  try {
    if (notifications.value?.length) {
      $modal.open(markRaw(NotificationModal), {
        notifications: notifications.value,
        action: () => {
          useRouter().push("/claim");
        },
      });
    } else {
      useRouter().push("/claim");
    }
  } catch (error) {
    console.error('Error while redirecting to "/claim":', error);
  }
}

const isLoading = ref(false);

async function initial() {
  try {
    isLoading.value = true;
    await setInitialCurrencies();
    await init();
  } catch (error) {
    console.error("Error while initializing exchange block", error);
  } finally {
    isLoading.value = false;
  }
}

onMounted(async () => {
  await initial();
});
</script>

<style lang="scss" module>
.wrap {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.course {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--body-color);

  @include respond-to(xs) {
    order: 1;
    font-size: 14px;
  }

  span {
    color: $text-color;
  }
}

.icon {
  width: 2.4rem;
  height: auto;
  color: var(--body-color);
}

.directions {
  display: flex;
  gap: 6rem;

  @include respond-to(sm) {
    gap: 40px;
  }

  @include respond-to(xs) {
    flex-direction: column;
    gap: 60px;
  }

  h3 {
    margin-bottom: 1rem;
  }
}

.direction {
  width: 100%;

  a {
    font-size: 1.4rem;
    text-decoration: underline;
  }
}

.networks {
  margin-bottom: 2rem;
}

.combobox {
  margin-bottom: 1rem;
}

.currency {
  width: 2.2rem;
  height: auto;
}

.sales {
  font-weight: 400;
  font-size: 1.5rem;
  margin-top: 0.8rem;
  margin-bottom: 0.5rem;
}

.btn {
  order: 2;
  max-width: 35.6rem;
  margin: 0 auto;

  @include respond-to(sm) {
    max-width: unset;
    width: 100%;
  }
}

.mode {
  display: none;

  @include respond-to(md) {
    display: flex;
  }
}

.limits {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  color: #929292;
  font-size: 1.4rem;
  height: 1.5rem;
  gap: 1rem;

  @include respond-to(xs) {
    font-size: 12px;
    height: auto;
  }
}

.row {
  height: 1.5rem;
  margin-bottom: 2rem;

  @include respond-to(xs) {
    display: none;
  }
}

.second {
  @include respond-to(xs) {
    margin-bottom: 20px;
  }
}
</style>
