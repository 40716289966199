<template>
  <div :class="$style.IndexPage">
    <PagesHomeExchange />
    <PagesHomeInfo :class="$style.info" />
    <PagesHomeContest :class="$style.contest" />
    <PagesHomeReviews :class="$style.review" />
  </div>
</template>

<script setup lang="ts">
import { useCalculatorStore } from "~/stores/calculatorStore";
import { useAccountApi } from "~/api/useAccountApi";
import MessageModal from "~/components/layout/modals/MessageModal.vue";

const route = useRoute();
const router = useRouter();
const calculatorStore = useCalculatorStore();

const { query } = route;

const { activateAccount } = useAccountApi();
const { $modal } = useNuxtApp();

onMounted(async () => {
  if (query?.restore) {
    router.replace({
      path: "/recover",
      query: { code: query?.restore as string },
    });
  }
  if (query?.ref) {
    calculatorStore.referral = query?.ref as string;
  }
  if (query?.activate) {
    await activateAccount(query?.activate as string);
    $modal.open(markRaw(MessageModal), {
      title: "Уведомление",
      message: "Аккаунт успешно активирован",
    });
  }
});
</script>

<style lang="scss" module>
.IndexPage {
  display: block;
}

.review {
  padding-top: 11rem;

  @include respond-to(sm) {
    padding-top: 120px;
  }
}

.contest {
  padding: 13.6rem 0 12.8rem;

  @include respond-to(xs) {
    padding: 67px 0;
  }
}

.info {
  padding: 7rem 0;

  @include respond-to(sm) {
    padding: 140px 0;
  }

  @include respond-to(xs) {
    padding: 60px 0 120px;
  }
}
</style>
