<template>
  <div :class="$style.PairsItem" @click="clickDirection">
    <NuxtLink to="/" :class="$style.link" />
    <UiTooltipVTooltip
      txt="Выбирай популярную пару для быстрого обмена"
      :tooltip="tooltip"
      :class="$style.tooltip"
    >
      <SvgoIconTooltip />
    </UiTooltipVTooltip>
    <div :class="$style.direction">
      <div :class="$style.currency">
        <div :class="$style.circle">
          <img :src="fromDirection?.logo.simple" :alt="fromDirection?.name" />
        </div>
        <span>{{ fromDirection?.name }}</span>
      </div>
      <SvgoIconDownArrow :class="$style.arrow" />
      <div :class="$style.currency">
        <div :class="$style.circle">
          <img :src="toDirection?.logo.simple" :alt="toDirection?.name" />
        </div>
        <span>{{ toDirection?.name }}</span>
      </div>
    </div>
    <p :class="$style.course">
      Курс обмена <span>{{ courseStringTemplate() }}</span>
    </p>
  </div>
</template>

<script setup lang="ts">
import { useCalculator } from "~/composables/useCalculator";

const props = defineProps({
  currency: {
    type: Object,
    default: () => ({}),
  },
  course: {
    type: Number,
    default: 0,
  },
  from: {
    type: Number,
    default: 0,
  },
  to: {
    type: Number,
    default: 0,
  },
  tooltip: {
    type: Object,
    default: null,
  },
});

const { setFromCurrencyId, setToCurrencyId, getData, directions } =
  useCalculator();

const fromCurrency = computed(() => {
  return props.currency.from;
});

const toCurrency = computed(() => {
  return props.currency.to;
});

const fromDirection = computed(() => {
  if (directions.value) {
    return directions.value.find((item) => item.ids.includes(props.from));
  }
});

const toDirection = computed(() => {
  if (directions.value) {
    return directions.value.find((item) => item.ids.includes(props.to));
  }
});

function courseStringTemplate() {
  if (!props.course) return "";
  const courseNum = Number(props.course);

  if (courseNum >= 1) {
    return `1 ${fromCurrency.value} ~ ${courseNum} ${toCurrency.value}`;
  }
  return `${1 / courseNum} ${fromCurrency.value} ~ 1 ${toCurrency.value}`;
}

async function clickDirection() {
  const { from } = props;
  const { to } = props;

  await setFromCurrencyId(from);
  await setToCurrencyId(to);
  await getData();
}
</script>

<style lang="scss" module>
.PairsItem {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 3rem;
  border: 2px solid $body-color;
  background: $bg-color;
  width: calc((100% - 4rem) / 2);

  @include respond-to(md) {
    min-width: 380px;
  }

  @include respond-to(xs) {
    min-width: 100%;
  }

  svg {
    width: 2rem;
    height: auto;
    color: $base-500;
  }
}

.link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.tooltip {
  position: absolute;
  top: 3rem;
  right: 3rem;
  z-index: 2;
}

.direction {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.currency {
  display: flex;
  align-items: center;
  gap: 0.8rem;

  span {
    font-size: 2rem;
  }
}

.circle {
  width: 3.2rem;
  height: 3.2rem;
  flex-shrink: 0;
  background-color: $base-700;
  border-radius: 50%;

  img {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.arrow {
  width: 2.4rem;
  height: auto;
  transform: rotate(180deg);
  color: $brand-green;
}

.course {
  font-size: 1.4rem;
  color: $base-500;

  span {
    text-transform: uppercase;
  }
}
</style>
