<template>
  <div :class="$style.Tooltip">
    <button type="button" @focus="showTooltip($event)">
      <slot />
      <span :class="$style.wrap">
        <span :class="$style.content">{{ txt }}</span>
      </span>
    </button>
  </div>
</template>

<script setup lang="ts">
export interface Props {
  txt?: string;
  tooltip?: any;
}

const props = withDefaults(defineProps<Props>(), {
  txt: "",
  tooltip: null,
});

const style = useCssModule();

const distanceOutside = -50;
const distanceInside = 70;
function between(x: number, min: number, max: number) {
  return x >= min && x <= max;
}

function coordinatePosition(relativePos: any) {
  let classes = style.bottom;
  if (between(relativePos.left, distanceOutside, distanceInside)) {
    if (between(relativePos.top, distanceOutside, distanceInside))
      classes = style.topLeft;
    else classes = style.bottomLeft;
  } else if (between(relativePos.right, distanceOutside, distanceInside)) {
    if (between(relativePos.top, distanceOutside, distanceInside))
      classes = style.topRight;
    else classes = style.bottomRight;
  } else if (between(relativePos.top, distanceOutside, distanceInside)) {
    classes = style.top;
  }
  return classes;
}

function showTooltip(event: any) {
  const parentPos = props.tooltip.getBoundingClientRect();
  const childPos = event.target.getBoundingClientRect();
  const relativePos = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  };

  relativePos.top = childPos.top - parentPos.top;
  relativePos.right = childPos.right - parentPos.right;
  relativePos.bottom = childPos.bottom - parentPos.bottom;
  relativePos.left = childPos.left - parentPos.left;

  event.target.className = coordinatePosition(relativePos);
}
</script>

<style lang="scss" module>
.content {
  position: relative;
  display: block;
  width: 27.5rem;
  padding: 3rem;
  background: $bg-color;
  border: 1px solid $body-color;
  pointer-events: none;
}

.wrap {
  position: absolute;
}

.bottomRight {
  & .wrap {
    right: 0;
    top: calc(100% + 1rem);
  }
}

.topLeft {
  & .wrap {
    left: 0;
    bottom: calc(100% + 1rem);
  }
}

.bottomLeft {
  & .wrap {
    left: 0;
    top: calc(100% + 1rem);
  }
}

.topRight {
  & .wrap {
    right: 0;
    bottom: calc(100% + 1rem);
  }
}

.top {
  & .wrap {
    left: 50%;
    transform: translate(-50%);
    bottom: calc(100% + 1rem);

    @include respond-to(sm) {
      top: calc(100% + 1rem);
    }

    @include respond-to(xs) {
      bottom: calc(100% + 1rem);
    }
  }
}

.bottom {
  & .wrap {
    left: 50%;
    transform: translate(-50%);
    top: calc(100% + 1rem);
  }
}

.Tooltip {
  button {
    position: relative;
    display: flex;
    font-size: 1.6rem;

    & .wrap {
      visibility: hidden;
    }

    &:focus .wrap {
      visibility: visible;
    }
  }
}
</style>
