<template>
  <div ref="tooltip" :class="$style.ExchangePairs">
    <CommonContestBlock :class="$style.contest" />
    <template v-if="data">
      <PagesHomeExchangePairsItem
        v-for="({ course, currency, from, to }, index) in data.slice(0, 3)"
        :key="index"
        :currency="currency"
        :course="course"
        :from="from"
        :to="to"
        :tooltip="tooltip"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { useAccountApi } from "~/api/useAccountApi";

const { fetchBestPair } = useAccountApi();
const { data } = await fetchBestPair();

const tooltip = ref(null);
</script>

<style lang="scss" module>
.ExchangePairs {
  display: flex;
  align-items: stretch;
  gap: 2rem;
  margin-top: 2rem;

  @include respond-to(md) {
    margin-right: -97px;
    margin-left: -97px;
    padding-right: 97px;
    padding-left: 97px;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @include respond-to(xs) {
    margin-right: unset;
    margin-left: unset;
    padding-right: unset;
    padding-left: unset;
    overflow-x: unset;
    flex-direction: column;
  }

  svg {
    width: 2rem;
    height: auto;
    color: $base-500;
  }
}

.contest {
  display: none;
  min-height: 20rem;

  @include respond-to(md) {
    display: block;
    max-width: unset;
    width: unset;
    min-width: 270px;
    height: auto;
  }

  @include respond-to(xs) {
    display: none;
  }
}

.item {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 3rem;
  border: 2px solid $body-color;
  background: $bg-color;
  width: calc((100% - 4rem) / 2);

  @include respond-to(md) {
    min-width: 380px;
  }

  @include respond-to(xs) {
    min-width: 100%;
  }
}

.link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.tooltip {
  position: absolute;
  top: 3rem;
  right: 3rem;
  z-index: 2;
}

.direction {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.currency {
  display: flex;
  align-items: center;
  gap: 0.8rem;

  span {
    font-size: 2rem;
  }
}

.circle {
  width: 3.2rem;
  height: 3.2rem;
  flex-shrink: 0;
  background-color: $base-700;
  border-radius: 50%;

  img {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.arrow {
  width: 2.4rem;
  height: auto;
  transform: rotate(180deg);
  color: $brand-green;
}

.course {
  font-size: 1.4rem;
  color: $base-500;

  span {
    text-transform: uppercase;
  }
}
</style>
